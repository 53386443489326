/**
 * Typography
 */

* {
	box-sizing: border-box;
}
img {
	max-width: 100%;
}
html {
	overflow: hidden;
	overflow-y: auto;
}
body {
	overflow: hidden;
	font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-primary);
	color: var(--color-gray);
	font-weight: 400;
}

a {
	transition: all 0.4s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
	margin: 0;
	margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: $heading-font;
	line-height: 1.4074;
	color: $heading-color;
}
h1,
.h1 {
	font-size: var(--h1);
}
h2,
.h2 {
	font-size: var(--h2);
}
h3,
.h3 {
	font-size: var(--h3);
}
h4,
.h4 {
	font-size: var(--h4);
}
h5,
.h5 {
	font-size: var(--h5);
}
h6,
.h6 {
	font-size: var(--h6);
}

@media #{$md-layout} {
	h1,
	.h1 {
		font-size: var(--h1) - 4;
	}
	
	h2,
	.h2 {
		font-size: var(--h2) - 2;
	}
	h3,
	.h3 {
		font-size: var(--h3) - 2;
	}
}

@media #{$sm-layout} {
	h1,
	.h1 {
		font-size: var(--h1) - 6;
	}
	
	h2,
	.h2 {
		font-size:var(--h2) - 4;
	}
	h3,
	.h3 {
		font-size: var(--h3) - 2;
	}
}

@media #{$large-mobile} {
	h1,
	.h1 {
		font-size: var(--h1) - 8;
	}
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	font-weight: 700;
}

h4,
.h4,
h5,
.h5 {
	font-weight: 600;
}

h6,
.h6 {
	font-weight: 500;
}

p {
    font-size: var(--font-size-b1);
	line-height: var(--line-height-b1);
	margin-bottom: 30px;
    @media #{$sm-layout} {
        font-size: 16px !important;
        line-height: 28px !important;
    }
	br {
		@media #{$sm-layout} {
			display: none;
		}
	}
}

a:hover,
a:focus,
a:active {
	text-decoration: none;
	outline: none;
}
a:visited {
	color: inherit;
}
input,
button,
select,
textarea {
	background: transparent;
	border: 1px solid $border-color;
	transition: all 0.4s ease-out 0s;
	color: $body-color;
	&:focus,
	&:active {
		outline: none;
		border-color: var(--color-primary);
	}
}
input,
select,
textarea {
	width: 100%;
	font-size: 14px;
}
input,
select {
	height: 40px;
	padding: 0 15px;
}